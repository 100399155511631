import React from 'react';

const List = React.lazy(() => import('./List'));
const Create = React.lazy(() => import('./Create'));
const Edit = React.lazy(() => import('./Edit'));

export const goodsReceiptPagesConfigs = [
  {
    path: '/goods-receipt',
    element: <List />,
  },
  {
    path: '/goods-receipt/create',
    element: <Create />,
  },
  {
    path: '/goods-receipt/:id/:route_id/edit',
    element: <Edit />,
  },
];
